.form{
  padding-top: 40px;
  max-width: 400px;
  .formContainer{
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.15);
    padding:20px;
  background-color: white;
  }
  h4{
    text-align: left;
    align-items: flex-start;
    padding-bottom: 20px;
  }
  .container{
    display :flex;
    flex-direction: column;
    justify-content: center;
  }
  .MuiFormControl-root{
    margin-bottom: 15px;
  }
}
.flex{
  display: flex;
  &.column{
    flex-direction: column;
  }

}
.spaceBet{
  justify-content: space-between;
}
.centerItems{
  justify-content: center;
}
.imageContainer{
  display: flex;
  justify-content: center;
}
@media (max-width: 390px){
  .MuiTypography-h5{
    font-size: 15px !important;
  }
 .form{
   h4{
     font-size: 17px !important;
   }
 }
  .MuiInputLabel-formControl{
    font-size: 15px !important;
  }
}
.MuiButton-containedPrimary{
background-color: #5baf94 !important;
}
.MuiButton-contained.Mui-disabled{
  background-color: rgba(0, 0, 0, 0.12) !important;
}
.sentMenu{
  h3{
    color:rgb(92, 175, 149);
  }
}