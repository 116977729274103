.brands-container{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 4rem;
}
.homepage-content{
  padding:100px;
}
.logo-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 230px;
  justify-content: space-around;
  &-image{
    height: 150px;
    margin-bottom: 20px;
  }
  img{
    max-width:100%;
    max-height:100%;
  }
}
.afspraak-button{
  color:white !important;
  background-color:#0C2E40 !important;
  cursor: pointer;
  font-family: 'obvia,sans-serif', serif;

}

@media screen and(max-width: 769px){
  .brands-container{
    grid-template-columns: repeat(1,1fr);
  }

}