.footerContainer{
  a{
    color:white;
    &:hover{
      color:#EC9603;
    }
  }
}
.footerContainerLight{
background-color: white;
  div{
    color:#0C2E40
  }
}
